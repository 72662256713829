import { Component, OnInit, input} from '@angular/core';
import { CommonModule } from '@angular/common';  // Importa CommonModule
import { SaludoComponent } from '../saludo/saludo.component';


@Component({
  selector: 'app-colores',
  standalone: true,  // Asegúrate de que el componente sea autónomo
  imports: [CommonModule,SaludoComponent],  // Importa CommonModule para las directivas de Angular
  templateUrl: './colores.component.html',
  styleUrls: ['./colores.component.css']
})
export class ColoresComponent  {
  colorLocal: string = ''; // Inicializado con una cadena vacía
  constructor(){ }
  // Método para generar un valor hexadecimal aleatorio
  colorHex(): string {
   this.colorLocal = "#" + this.generarRamdom() + this.generarRamdom() + this.generarRamdom();
   return this.colorLocal;
  }

  // Método para generar un número aleatorio en formato hexadecimal
  generarRamdom(): string {
    return Math.floor(Math.random() * 255).toString(16).padStart(2, '0');  // Añadido padStart para asegurar que siempre tenga 2 dígitos
  }
}
