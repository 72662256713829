import { Component } from '@angular/core';

@Component({
  selector: 'app-lista-de-libros',
  imports: [],
  templateUrl: './lista-de-libros.component.html',
  styleUrl: './lista-de-libros.component.css'
})
export class ListaDeLibrosComponent {

}
