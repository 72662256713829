import { Component } from '@angular/core';

@Component({
  selector: 'app-error400',
  imports: [],
  templateUrl: './error400.component.html',
  styleUrl: './error400.component.css'
})
export class Error400Component {

}
