import { Component } from '@angular/core';

@Component({
  selector: 'app-acerca-de',
  imports: [],
  templateUrl: './acerca-de.component.html',
  styleUrl: './acerca-de.component.css'
})
export class AcercaDeComponent {

}
