import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detalles',
  templateUrl: './detalles.component.html',
  styleUrls: ['./detalles.component.css']
})
export class DetallesComponent implements OnInit {
  libroSeleccionado: { titulo: string, autor: string, descripcion: string } | undefined;

  constructor() { }

  ngOnInit(): void {
    this.libroSeleccionado = {
      titulo: 'Título del libro',
      autor: 'Autor del libro',
      descripcion: 'Descripción del libro'
    };
  }
}
