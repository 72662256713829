import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aleatorio',
  imports: [],
  templateUrl: './aleatorio.component.html',
  styleUrl: './aleatorio.component.css',
  standalone: true  // Asegúrate de que el componente sea autónom
})
export class AleatorioComponent implements OnInit{
  aleatorio:Number = Math.floor( Math.random() *100 );

  constructor() { }
    ngOnInit() {

    
  }

}
