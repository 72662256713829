<app-encabezado></app-encabezado>

<!-- Lista de libros con enlaces a Google -->
<ul>
  <li *ngFor="let libro of libros">
    <a href="https://google.com/search?q={{ libro.autor }}"> {{ libro.titulo }} </a>
  </li>
</ul>

<!-- Lista con información adicional sobre libros -->
<ul>
  <li *ngFor="let libro of libros; index as indice; first as esPrimero; last as esUltimo; odd as esPar">
    <a (click)="mostrarAutor(libro)">  {{ libro.titulo }} </a>
    <span *ngIf="indice == 5" > Destacado</span>
    <span *ngIf="esPrimero" > Destacado</span>
    <span *ngIf="esUltimo" > Destacado</span>
    <span *ngIf="esPar" > Destacado</span>
  </li>
</ul>

<!-- Div con el fondo dinámico -->
<div class="cuadrado" [ngStyle]="{'background': color ? color : 'peru'}">
</div>

<!-- Botones para cambiar el color -->
<button (click)="cambiarColor('red')">Rojo</button>
<button (click)="cambiarColor('green')">Verde</button>
<button (click)="cambiarColor('blue')">Azul</button>

<!-- ngSwitch para mostrar el destino basado en el día de la semana -->
<div [ngSwitch]="diasSemana">
  <p *ngSwitchDefault>Elige un día de la semana para conocer tu destino</p>
  <p *ngSwitchCase="'lunes'">Tomar café y trabajar</p>
  <p *ngSwitchCase="'martes'">No casarme y evitar barcos</p>
  <p *ngSwitchCase="'miércoles'">Sobrevivir al día más largo de la semana</p>
  <p *ngSwitchCase="'jueves'">Nacer, simularlo el viernes</p>
  <p *ngSwitchCase="'viernes'">Tomar café y trabajar</p>
  <p *ngSwitchCase="'13'">-------13--------</p>
</div>

<!-- Input para cambiar el valor de diasSemana -->
<input type="text" [(ngModel)]="diasSemana" placeholder="Escribe un día de la semana">

<!-- Bloques de texto que se muestran dependiendo de los valores de valor1 y valor2 -->
<div class="contenedor">
  <div *ngIf="valor1">
    <h1>TEXTO DE EJEMPLO</h1>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta hic explicabo
       neque quam illum quisquam ea dolor, rerum laboriosam consequatur nemo 
      voluptatibus laborum sapiente a itaque accusamus porro quo doloribus!</p>
  </div>
</div>

<div class="contenedor">
  <div *ngIf="valor2; else otroTexto">
    <h1>TEXTO DE EJEMPLO 2</h1>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta hic explicabo
       neque quam illum quisquam ea dolor, rerum laboriosam consequatur nemo 
      voluptatibus laborum sapiente a itaque accusamus porro quo doloribus!</p>
  </div>
  <ng-template #otroTexto>
    <p>Este es el texto alternativo cuando valor2 es falso.</p>
  </ng-template>
</div>

<!-- Título con un estilo dinámico -->
<h1 [ngStyle]="{'background': colorHex(), 'font-size': fontsize, 'color':'white','border': '2px solid black'}">
  {{ title }}
</h1>

<!-- Botones para mostrar texto -->
<button (click)="valor1=true">Mostrar texto 1</button>
<button (click)="valor2 = !valor2">Mostrar texto 2</button>

<!-- Componentes personalizados -->
<app-aleatorio></app-aleatorio>
<app-colores></app-colores>

<hr>

<!-- Mostrar valores de las variables -->
<p>Valor1: {{ valor1 }}</p>
<p>Valor2: {{ valor2 }}</p>

<!-- Ruta de salida para la navegación -->
<router-outlet></router-outlet> <!-- Este es el contenedor donde se renderiza el componente de la ruta -->
