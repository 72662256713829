import { Component } from '@angular/core';

@Component({
  selector: 'app-segundo-componente',
  standalone: true,  // Marca el componente como standalone
  imports: [],  // Agrega otros componentes si es necesario
  templateUrl: './segundo-componente.component.html',
  styleUrls: ['./segundo-componente.component.css']
})
export class SegundoComponenteComponent {
  // Lógica del componente
}
