// app-routing.module.ts
import {NgModule} from '@angular/core';
import { Routes, RouterModule} from '@angular/router';
import { SegundoComponenteComponent } from './segundo-componente/segundo-componente.component';
import { AppComponent } from './app.component'; // O el componente predeterminado que quieras mostrar
import { ListaDeLibrosComponent } from './lista-de-libros/lista-de-libros.component';
import { DetallesComponent } from './detalles/detalles.component';
import { EncabezadoComponent } from './encabezado/encabezado.component';
import { Error400Component } from './error400/error400.component';
import { InicioComponent } from './inicio/inicio.component';
import { AcercaDeComponent } from './acerca-de/acerca-de.component';


export const routes: Routes = [
 { path: '', component: AppComponent }, // Ruta predeterminada
 { path: 'segundo', component: SegundoComponenteComponent },  // Ruta que apunta al SegundoComponenteComponent
 { path:'lista-de-libros', component:ListaDeLibrosComponent}, 
  
{ path:'detalles', component:DetallesComponent},
// { path:'detalles/:libroId', component:DetallesComponent},
 { path:'encabezado', component:EncabezadoComponent},
 { path:'error400', component:Error400Component},
 { path:'inicio',component:InicioComponent},
 { path:'acerca-de', component: AcercaDeComponent}, 
 { path:'404', component: Error400Component}, 
 { path:'**', redirectTo: '404'}, 
 //{ path:'', pathMatch:'full', redirectTo: 'lista-de-libros'}, 

       
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }
  


