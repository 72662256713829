import { Component, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColoresComponent } from './colores/colores.component';
import { FormsModule } from '@angular/forms';
import { RouterOutlet, RouterModule } from '@angular/router';

import { AleatorioComponent } from './aleatorio/aleatorio.component';
import { AcercaDeComponent } from './acerca-de/acerca-de.component';
import { DetallesComponent } from './detalles/detalles.component';
import { EncabezadoComponent } from './encabezado/encabezado.component';
import { Error400Component } from './error400/error400.component';
import { ListaDeLibrosComponent } from './lista-de-libros/lista-de-libros.component';
import { InicioComponent } from './inicio/inicio.component';

// Definir la interfaz Libro para tipar la propiedad libros
interface Libro {
  id: number;
  titulo: string;
  autor: string;
  descripcion: string;
}

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    AleatorioComponent,
    ColoresComponent,
    FormsModule,
    RouterOutlet,
    AcercaDeComponent,
    DetallesComponent,
    EncabezadoComponent,
    Error400Component,
    ListaDeLibrosComponent,
    InicioComponent,
    RouterModule
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  libros: Libro[];  // Tipado con la interfaz Libro
  title = 'Mi aplicación Angular 01';
  color: string = 'white';
  fontsize: string = '24px';
  valor1: boolean = false;
  valor2: boolean = false;
  diasSemana: string = '';
  

  constructor(private cdr: ChangeDetectorRef) {
    // Asignación de los libros con la nueva interfaz
    this.libros = [
      { id: 1, titulo: 'El retrato de Dorian Gray', autor: 'Oscar Wilde', descripcion: "Lorem ipsum dolor sit amet..." },
      { id: 2, titulo: 'Frankenstein', autor: 'Mary Shelley', descripcion: "Lorem ipsum dolor sit amet..." },
      { id: 3, titulo: 'Orgullo y prejuicio', autor: 'Jane Austen', descripcion: "Lorem ipsum dolor sit amet..." },
      { id: 4, titulo: 'Drácula', autor: 'Bram Stoker', descripcion: "Lorem ipsum dolor sit amet..." },
      { id: 5, titulo: 'El maravilloso mago de Oz', autor: 'L. Frank Baum', descripcion: "Lorem ipsum dolor sit amet..." },
      { id: 6, titulo: 'Don Quijote de la Mancha', autor: 'Miguel de Cervantes Saavedra', descripcion: "Lorem ipsum dolor sit amet..." },
      { id: 7, titulo: 'La máquina del tiempo', autor: 'H. G. Wells', descripcion: "Lorem ipsum dolor sit amet..." },
      { id: 8, titulo: 'El conde de Montecristo', autor: 'Alexandre Dumas', descripcion: "Lorem ipsum dolor sit amet..." },
      { id: 9, titulo: 'Mujercitas', autor: 'Louisa M. Alcott', descripcion: "Lorem ipsum dolor sit amet..." },
      { id: 10, titulo: 'La isla del tesoro', autor: 'Robert Louis Stevenson', descripcion: "Lorem ipsum dolor sit amet..." }
    ];
  }

  generarRandom(): string {
    return Math.floor(Math.random() * 255).toString(16);
  }

  colorHex(): string {
    return "#" + this.generarRandom() + this.generarRandom() + this.generarRandom();
  }

  cambiarColor(newColor: string) {
    this.color = newColor;
  }

  ngAfterViewInit(): void {
    // Usar setTimeout para forzar la detección de cambios después de la inicialización de la vista
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 0);
  }
  // Método que se llama cuando haces clic en un libro
  mostrarAutor(libro: any) {
    alert(`El autor del libro "${libro.titulo}" es ${libro.autor}`);
  }
  
}
